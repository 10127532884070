@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

body {
  font-family: Roboto;
}

/* IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.scrollbar-themed {
  scrollbar-color: #424242 transparent;
}

/* Chrome, Safari, Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
.scrollbar-themed::-webkit-scrollbar-track {
  background: transparent;
}
.scrollbar-themed::-webkit-scrollbar-thumb {
  background-color: #424242;
}
